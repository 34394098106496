import PocketBase from "pocketbase";
import type { UsersRecord } from "~/types/pocketbase-types";

enum ROLES {
  DEVELOPER = "05riifogj9haw1q",
  PUPIL = "cf9vyge6e2jgh5j",
  TEACHER = "02g9lew5evosk38",
}

export const usePocketBase = defineStore("pocketbase", () => {
  const config = useRuntimeConfig();

  const pocketbase = ref(new PocketBase(config.public.pocketBaseDomain));

  function getUser() {
    return (
      (pocketbase.value.authStore.model as UsersRecord | null) ?? undefined
    );
  }

  const user = ref<UsersRecord | undefined>(getUser());

  const hasTeacherAccess = computed<boolean>(() => {
    if (!user.value) {
      return false;
    }

    return (
      user.value.roles === ROLES.TEACHER || user.value.roles === ROLES.DEVELOPER
    );
  });
  const hasDeveloperAccess = computed<boolean>(() => {
    if (!user.value) {
      return false;
    }

    return user.value.roles === ROLES.DEVELOPER;
  });

  async function loginDiscord() {
    await pocketbase.value
      .collection("users")
      .authWithOAuth2({ provider: "discord" });
  }

  const isLoggedIn = computed<boolean>(() => user.value !== undefined);
  async function logout() {
    pocketbase.value.authStore.clear();
  }

  pocketbase.value.authStore.onChange(async (auth) => {
    if (auth.length === 0) {
      user.value = undefined;
      return;
    }

    user.value = pocketbase.value.authStore.model as UsersRecord;
  }, true);

  return {
    pocketbase,

    user,
    hasTeacherAccess,
    hasDeveloperAccess,

    logout,
    loginDiscord,
    isLoggedIn,
  };
});
